import styled from "styled-components";

export default styled.div<{
  ai?: "baseline" | "center" | "end" | "start" | "stretch";
  aself?: "auto" | "baseline" | "center" | "end" | "start" | "stretch";
  fd?: "column" | "column-reverse" | "row" | "row-reverse";
  jc?: "around" | "space-between" | "center" | "end" | "start" | "evenly";
  fw?: "noWrap" | "wrap" | "wrap-reverse";
  gap?: number;
}>`
  align-items: ${(props) => props.ai || "stretch"};
  align-self: ${(props) => props.aself || "auto"};
  display: flex;
  flex-direction: ${(props) => props.fd || "row"};
  flex-shrink: 1;
  flex-wrap: ${(props) => props.fw || "wrap"};
  justify-content: ${(props) => props.jc || "flex-start"};
  gap: ${(props) => props.gap || 0}px;
`;
