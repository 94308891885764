import React from "react";
import { Link } from "react-router-dom";
import { Box, Header, Button, Container, Footer } from "../components";
import colors from "../theme/colors";
import { EContainerVariant } from "../types";
import useThemeContext from "../hooks/useThemeContext";
import { Logo as logoFooterSrc } from "../assets/svg";

const Home = () => {
  const {
    isAccessibilityModeActive,
    switchAccessibilityMode,
  } = useThemeContext();
  return (
    <div>
      <Header
        color={colors.primary}
        toggle={{
          label: "Accessibilité",
          active: isAccessibilityModeActive,
          handleChange: switchAccessibilityMode,
        }}
      >
        Formulaires d'adhésion
      </Header>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Container variant={EContainerVariant.Small}>
        <Box jc="center" gap={40}>
          <Link to="/france">
            <Button>France M&A</Button>
          </Link>
          <Link to="/patrimoine">
            <Button>Patrimoine</Button>
          </Link>
          <Link to="/assurance">
            <Button>Assurance</Button>
          </Link>
          <Link to="/credit">
            <Button>Crédit</Button>
          </Link>
          <Link to="/immobilier">
            <Button>Immobilier</Button>
          </Link>
          {/* <Link to="/cncef">
            <Button mt>CNCEF</Button>
          </Link> */}
        </Box>
      </Container>
      <br />
      <br />
      {/* <Text center variant={ETextVariant.Title1}>
        Formulaires de renouvellement
      </Text>
      <Container variant={EContainerVariant.Small}>
        <Box jc="space-between">
          <Link to="/credit-renewal">
            <Button mt>Crédit</Button>
          </Link>
          <Link to="/assurance-renewal">
            <Button mt>Assurance</Button>
          </Link>
          <Link to="/assurance-credit-renewal">
            <Button mt>Assurance & Crédit</Button>
          </Link>
        </Box>
      </Container> */}

      <Footer
        logo={logoFooterSrc}
        links={[
          {
            url: "https://www.cncef.org/mentions-legales/",
            title: "Mentions légales",
          },
          {
            url: "https://www.cncef.org/contact/",
            title: "Contact",
          },
        ]}
      >
        <p>CNCEF PATRIMOINE, association professionnelle agréée par l'AMF</p>
        <p>CNCEF ASSURANCE, association professionnelle agréée par l'ACPR</p>
        <p>CNCEF CRÉDIT, association professionnelle agréée par l'ACPR</p>
      </Footer>
    </div>
  );
};

Home.propTypes = {};

export default Home;
