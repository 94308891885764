import styled from "styled-components";
import { breakpoints, colors, fonts } from "../../theme";

export const StyledHeader = styled.div`
  display: flex;

  ${breakpoints.max.md} {
    align-items: center;
  }
`;
export const LogoWrapper = styled.a<{ color: string }>`
  display: block;
  padding: 3rem 5rem;
  position: relative;

  &:before {
    position: absolute;
    z-index: 0;
    top: 15%;
    right: -0.5rem;
    content: "";
    display: block;
    width: 0.9rem;
    height: 70%;
    background-color: ${(props) => props.color};
  }
  ${breakpoints.max.md} {
    padding: 2rem 3rem;
  }
`;
export const Logo = styled.img`
  width: 250px;
`;
export const Content = styled.div`
  padding: 3rem 5rem;
  background-color: ${colors.greyLightest};
  width: 100%;
  display: flex;
  ${breakpoints.max.lg} {
    padding: 2rem 3rem;
  }

  ${breakpoints.max.md} {
    br {
      display: none;
    }
    p {
      font-size: ${fonts.size.xl};
    }
  }
  ${breakpoints.max.sm} {
    display: block;
  }
`;

export const ToggleWrapper = styled.div`
  margin-left: auto;
  ${breakpoints.max.sm} {
    margin-top: 1rem;
  }
`;

export const StyledLink = styled.p`
  font-size: ${fonts.size.s};
  text-decoration: underline;
  cursor: pointer;
  margin-top: 1rem;
`;
